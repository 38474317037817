<template lang="pug">
v-container.body-1
  v-layout(row).display-1.font-weight-bold
    v-flex
      h3.mb-3 ink ticket
    v-flex.text-xs-right
      p job # {{job.jobNumber}} 

  v-layout(row).mb-5
    v-flex().title
      span.font-weight-bold {{company.name}} -
      | {{job.design.name}}
      span(v-if="job.subtitle") - {{job.subtitle}}  

  v-layout(row)
    v-flex(xs8)
      v-layout(row)
        v-flex
          span.font-weight-bold job description: 
          | {{job.design.subtitleText}}
      v-layout(row)
        v-flex
          span.font-weight-bold order name: 
          | {{order.name}} / order # {{order.orderNumber}}

      v-layout(row mt-3 v-if="order.dates.inHands")
        v-flex()
          span.font-weight-bold in hands date: 
          | {{formatDate((order.dates.inHands))}}
      v-layout(row v-if="order.dates.dropDead")
        v-flex()
          span.font-weight-bold drop dead date: 
          | {{formatDate(order.dates.dropDead)}}
      v-layout(row v-if="order.owner.username")
        v-flex()
          span.font-weight-bold owner: 
          span {{order.owner.username}}
      v-layout(row v-if="order.contact.fullName.trim()")
        v-flex()
          span.font-weight-bold point of contact: 
          span {{order.contact.fullName}} - {{order.contact.email}}
    v-flex(xs4).pl-3
      v-img(:src='design.image.url')


  v-layout.mt-5(row)
    v-flex
      FwdCard
        template(slot="title") inks: {{location.template.name}}
        template(slot="content")
          InkList(:location='location') 

</template>

<script>
import { mapFields } from 'vuex-map-fields'
import moment from 'moment'
import InkList from '@/modules/designs/components/color-list'

export default {
  components: { InkList },
  computed: {
    ...mapFields('productionInstances', {
      item: 'item',
    }),
    job () { return this.$store.getters['jobs/item'] },
    order () { return this.$store.getters['orders/item'] },
    company () { return this.$store.getters['companies/item'] },
    design () { return this.$store.getters['designs/item'] },
    location () {
      const item = this.design
      return item.locations.find(loc => loc._id === this.item.location._id)
    },
  },
  methods: {
    formatDate (date) { return moment(date).format('LLL') }
  },
  
  async created () {
    await this.$store.dispatch('productionInstances/_READ_FROM_DB', this.$route.params.id)
  }
}
</script>
